export const SET_NEWS = "setNews";

export default {
  state: {
    news: [],
  },
  getters: {
    getNews(state) {
      return state.news;
    },
  },
  actions: {
    [SET_NEWS](state, payload) {
      state.commit(SET_NEWS, payload);
    },
  },
  mutations: {
    [SET_NEWS](state, payload) {
      state.news = {
        ...state.news,
        ...payload,
      };
    },
  },
};
