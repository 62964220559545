import Swal from "sweetalert2/dist/sweetalert2.min.js";
export default function useSweetalert() {
  const Sconfirm = (text, icon) => {
    return Swal.fire({
      padding: "2em",
      text: text,
      icon: icon,
      showCancelButton: true,
      confirmButtonColor: "#009EF7",
      cancelButtonColor: "#F1416C",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก!",
      reverseButtons: true,
    });
  };
  const SconfirmNc = (text, icon, textbt) => {
    return Swal.fire({
      padding: "2em",
      text: text,
      icon: icon,
      buttonsStyling: false,
      confirmButtonText: textbt,
      confirmButtonColor: "#009EF7",
      customClass: {
        confirmButton: "btn fw-bold btn-light-primary",
      },
    });
  };

  const SToast = (icon, title) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: icon,
      title: title,
    });
  };

  const SNews = (title, detail, image) => {
    return Swal.fire({
      padding: "2em",
      title: title,
      imageUrl: image,
      imageWidth: 300,
      text: detail,
      showCloseButton: true,
      showCancelButton: false,
      showConfirmButton: false,
      customClass: {
        closeButton: "swal2-close-button",
      },
    });
  };

  const SConfirmh = (title, confirm_bt, deny_bt , cancle_bt) => {
    return Swal.fire({
      html: `<h2>${title}</h2>`,
      showCancelButton: true,
      showDenyButton: true,
      confirmButtonColor: "#009EF7",
      denyButtonColor: "#17A2B8",
      confirmButtonText: confirm_bt,
      denyButtonText: deny_bt,
      cancelButtonText: cancle_bt,
      customClass: {
        confirmButton: "btn-primary",
        denyButton: "btn-light-primary",
      },
    });
  };

  const Shealthinfo = (title, data) => {
    return Swal.fire({
      title : title,
      html: `<h3 style="font-weight:400;">คุณ ${data.firstname} ${data.lastname}</h3>
      <h6>หมายเลขบัตรประชาชน ${data.id_card}</h6>  
      <h6>เพศ ${data.gender} กรุ๊ปเลือด ${data.blood_type} อายุ ${data.age}</h6>
       <h6> ที่อยู่ ${data.numberhome} หมู่ ${data.moo} ตำบล ${data.district} อำเภอ ${data.amphoe}</h6> <h6>จังหวัด ${data.province}</h6>
       <div class="container p-2 text-start"><h6>
       <li>โรคประจำตัว : ${data.self_disease == null ? '-' : data.self_disease}</li>
       <li>อัตราการเต้นของหัวใจ : ${data.heart_rate == null ? '-' : data.heart_rate}</li>
       <li>ความดัน (ปัจจุบัน) : [${data.upper_pressure == null ? '-' : data.upper_pressure},${data.lower_pressure == null ? '-' : data.lower_pressure}]</li>
       <li>อุณภูมิร่างกาย : ${data.helth_temp} องศา</li>
       </h6>
       
       </div>
       `,
      showConfirmButton: false,
      allowOutsideClick: true,
      allowEscapeKey: false,
      allowEnterKey: false,
      backdrop: 'rgba(0,0,0,0.4)',
      heightAuto: false,
      showCloseButton: false,
      showCancelButton: false,
      showLoaderOnConfirm: true,
    })
  };

  return { Sconfirm, SToast, SconfirmNc, SNews, SConfirmh ,Shealthinfo};
}
