<template>
  <div class="bg-home">
    <div class="card concard card-shadow">
      <Header></Header>

      <div class="card-body mt-1">

        <div class="row">


          <div class="col-8 p-1 news-scroll">
            <div v-for="(data, index) in news" :key="index" @click="go_detail(index)" class="info-box mb-3"
              style="background-color: #FFFFFF;">

              <img :src="data.picture">
              <div class="info-box-body">

                <span>
                  <div class="info-box-title">
                    {{ data.title }}
                  </div>
                  <div class="info-box-detail">
                    {{ data.detail }}
                  </div>

                  <h6 class="mt-3">{{ dateThai(data.created_at) }}</h6>
                </span>
              </div>
            </div>
          </div>





          <div class="col-4 p-1">
            <!-- <div class="box-main d-flex align-items-center justify-content-center flex-column mb-2"  style="color: #570C7A;font-weight:500" 
              @click="register_confirm">

              <i class="bi bi-clipboard-heart-fill" style="color: #570C7A; font-size:30px"></i><span>ประวัติสุขภาพ</span>
            </div> -->
            <!-- <a href="tel:191"> -->
            <div class="box-main d-flex align-items-center justify-content-center flex-column text-white"
              @click="sos_alert" style="background-color: #FC1515; height: 210px;">

              <div class="box-main d-flex position-absolute top-0 mt-2 justify-content-center"
                style="background-color: #FFBE33; height: 50%; width: 90%;">

                <img src="@/assets/siren.png" style="width: auto; height: 120px; bottom: 0;" />
              </div>


              <h6 class="position-absolute" style="bottom:22%">ขอความช่วยเหลือ</h6>
              <h1 class="position-absolute" style="bottom:4%;font-size: 40px;">ฉุกเฉิน!</h1>

            </div>
            <!-- </a> -->
          </div>



        </div>

      </div>

    </div>

  </div>
</template>

<script>
import useHealth from "@/service/api/health";
import useSweetalert from "@/service/sweetalert2";
import { defineComponent, onMounted, inject, ref } from "vue";
import Header from "@/components/Header";
import moment from "moment";
import leaflet from "leaflet";
import {
  SET_SOS
} from "@/store/modules/HealthModel";
export default defineComponent({
  name: "Dashboard",
  components: {
    Header,
  },
  setup() {
    const { Sos_alert } = useHealth();
    const { Sconfirm, SconfirmNc, SToast } = useSweetalert();
    let isLoading = ref(false);
    let color = ref(process.env.VUE_APP_COLOR);
    const store = inject("store");
    const router = inject("router");
    const news = store.getters.getNews;
    const { SNews } = useSweetalert();
    const Profile = store.getters.getProfire;

    function dateThai(date) {
      let y = moment(date).format("yyy");
      let year = parseInt(y) + 543;
      let march = moment(date).locale("th");

      return march.format("วันที่ D MMM " + year);
    }



    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }
    });

    const go_detail = (index) => {
      SNews(news[index].title, news[index].detail, news[index].picture);
    }

    const sos_alert = () => {
      let values = [];
      navigator.geolocation.getCurrentPosition((position) => {

        let newLatLng = new leaflet.LatLng(
          position.coords.latitude,
          position.coords.longitude
        );

        values.latitude = newLatLng.lat;
        values.longitude = newLatLng.lng;
        values.id_line = store.getters.getAccessToken.accesstoken;
        values.firstname = Profile.firstname;
        values.lastname = Profile.lastname;
        values.tel = Profile.tel;
        store.dispatch(SET_SOS, values);
      });





      Sconfirm("ยืนยัน การแจ้งเหตุฉุกเฉิน", "question").then(async (result) => {
        if (result.isConfirmed) {
          isLoading.value = true;

          await Sos_alert(store.getters.getSos)
            .then((result) => {
              isLoading.value = false;
              if (result.data.status === "success") {
                SconfirmNc("แจ้งเหตุฉุกเฉินสำเร็จ", "success", "ตกลง").then(async (result) => {
                  if (result.isConfirmed) {
                    window.open('tel:1669');
                  } else {
                    window.open('tel:1669');
                  }
                });
              }
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data.errors;
                if (errors.id_line) {
                  SToast("error", `${errors.id_line[0]}`);
                } else {
                  SToast("error", "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง");
                }
              }
            });
        }
      });



    }

    return {
      go_detail,
      news,
      dateThai,
      sos_alert,
      color
    };

  },
});
</script>

<style scoped>
.bg-home {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("~@/assets/bg-dash.png");
}

.info-box {
  color: #373737 !important;
  box-sizing: border-box;
  display: flex;
  border-radius: 22px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  height: 100px;
  width: 95% !important;
  cursor: pointer;
  padding: 0px;
  align-items: center;
  /* Some padding */
}

.info-box img {
  /* Set the width to 100% to fit the image inside the button */
  width: 80px;
  border-radius: 22px 0px 0px 22px;
  height: 100px;
  vertical-align: middle;
  margin-right: 20px;
  /* Add some space between the image and the text */
}


.info-box:hover,
.info-box:focus {
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  color: rgba(0, 0, 0, 0.65);
}

.info-box:hover {
  transform: translateY(-1px);
}

.info-box:active {
  background-color: #d4d4d4;
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  color: rgba(0, 0, 0, 0.65);
  transform: translateY(0);
}

.col-7 {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.info-box-body {
  width: fit-content;
  display: flex;
  position: relative;
}

.info-box-title {
  margin-top: 5px;
  width: 50%;
  font-size: 20px;
  font-weight: 800;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.info-box-detail {
  width: 10rem;
  font-size: 14px;
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.news-scroll {
  height: 40rem !important;
  /* set a fixed height for the card body */
  overflow: auto;
  /* enable a scrollbar when the content overflows */
}

.swal2-close-button {
  position: absolute;
  top: 0;
  right: 0;
}

.swal2-button-horizontal {
  padding: 10px 20px;
  border: 1px solid #ccc;
}
</style>
