<template>
  <div class="row">
    <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true" :color="color" loader="dots" />
    <div class="col-12 f13">
      <div class="card concard card-shadow">
        <div class="card-header white text-center">
          <img class="mt-2" src="@/assets/logo.png"
            style="width: 100px; border-radius: 50%;   box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;" />

          <div class="t1" style="margin: 5px; font-size:18px "> ลงทะเบียนข้อมูลสุขภาพ (ข้อมูลเพิ่มเติม)</div>
          <div class="t1" style="margin: 5px">ระบุตำแหน่งพิกัดบ้าน</div>
        </div>
        <Form @submit="onSubmitRegister" :validation-schema="register" id="kt_account_profile_details_form"
          ref="formContainer" novalidate="novalidate">
          <div class="card-body mb-47 mt-2 white">
            <!--begin::Card body-->
            <div class="card-body">
              <div class="row mb-1">
                <div id="mapid" style="height: 450px"></div>
                <Field type="hidden" name="latitude" v-model="latitude" />
                <Field type="hidden" name="longitude" v-model="longitude" />
                <div>
                  <div class="myl" @click="mylo">
                    ตำแหน่งของฉัน<i class="bi bi-geo-fill" style="margin-left: 10px"></i>
                  </div>
                </div>
              </div>
            </div>


            <div class="row" style="padding:16px 16px 0px 16px">
              <!--begin::Label-->
              <label class="col-lg-4 col-form-label required fw-bold fs-6">วันเกิด</label>
              <div class="col-lg-8 fv-row">
                <div class="row">
                  <div class="col-3">
                    <Field as="select" name="day" class="form-control form-control-solid" v-model="data_register.day">
                      <option value="" disabled selected>วัน</option>
                      <option v-for="index in 31" :key="index" :value="index">
                        {{ index }}
                      </option>
                    </Field>
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="day" />
                      </div>
                    </div>
                  </div>
                  <div class="col-5">
                    <Field as="select" name="month" class="form-control form-control-solid" v-model="data_register.month">
                      <option value="" disabled selected>เดือน</option>
                      <option v-for="n in monthNamesThai" :key="n.id" :value="n.id">
                        {{ n.name }}
                      </option>
                    </Field>
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="month" />
                      </div>
                    </div>
                  </div>

                  <div class="col-4">
                    <Field as="select" name="year" class="form-control form-control-solid" v-model="data_register.year">
                      <option value="" disabled selected>ปี (พ.ศ.)</option>
                      <option v-for="(n, index) in setRange()" :key="index" :value="n">
                        {{ n }}
                      </option>
                    </Field>
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="year" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mb-6" style="padding:16px">
              <!--begin::Label-->
              <label class="col-lg-4 col-form-label fw-bold fs-6 required">รหัสบัตรประชาชน</label>
              <!--end::Label-->

              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <Field type="text" name="id_card" class="form-control  form-control-solid" placeholder="รหัสบัตรประชาชน"
                  maxlength="13" v-model="data_register.id_card" />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="id_card" />
                  </div>
                </div>
              </div>
              <!--end::Col-->
            </div>
          </div>

          <div class="card-footer white">
            <div class="d-flex justify-center align-center">

              <div style="width: 100%; padding: 0px 5px">
                <button type="submit" style="width: 100%" class="button">
                  ลงทะเบียนข้อมูลสุขภาพ
                </button>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment/dist/moment.js";
import useRegister from "@/service/api/register";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { defineComponent, onMounted, inject, ref } from "vue";
import { SET_REGISTER } from "@/store/modules/RegisterModel";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as Yup from "yup";
import leaflet from "leaflet";
import useSweetalert from "@/service/sweetalert2";
import liff from "@line/liff";
export default defineComponent({
  name: "Register",
  components: {
    ErrorMessage,
    Field,
    Form,
    Loading
  },
  setup() {

    const monthNamesThai = ref([
      { id: "01", name: "มกราคม" },
      { id: "02", name: "กุมภาพันธ์" },
      { id: "03", name: "มีนาคม" },
      { id: "04", name: "เมษายน" },
      { id: "05", name: "พฤษภาคม" },
      { id: "06", name: "มิถุนายน" },
      { id: "07", name: "กรกฎาคม" },
      { id: "08", name: "สิงหาคม" },
      { id: "09", name: "กันยายน" },
      { id: "10", name: "ตุลาคม" },
      { id: "11", name: "พฤษจิกายน" },
      { id: "12", name: "ธันวาคม" },
    ]);


    let isLoading = ref(false);
    let color = ref(process.env.VUE_APP_COLOR);
    const { Sconfirm, SconfirmNc, SToast } = useSweetalert();
    let mymap;
    const store = inject("store");
    const router = inject("router");
    const data_register = store.getters.getRegister;
    const { UpdateUser } = useRegister();

    let latitude = ref(store.getters.getRegister.latitude);
    let longitude = ref(store.getters.getRegister.longitude);
    let marker = ref([]);
    const phoneRegExp =
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

    const register = Yup.object().shape({
      latitude: Yup.string(),
      longitude: Yup.string(),
      id_card: Yup.string()
        .required("กรุณากรอก รหัสบัตรประชาชน").min(13, 'กรุณากรอก เลขบัตรประชาชนให้ครบ').matches(phoneRegExp, "รูปแบบเลขบัตรประชาชนไม่ถูกต้อง"),
      day: Yup.string().required("กรุณาเลือก วัน"),
      month: Yup.string().required("กรุณาเลือก เดือน"),
      year: Yup.string().required("กรุณาเลือก ปี"),

    });

    function setLocation() {
      if (!navigator.geolocation) {
        alert("Geolocation is not supported by your browser");
      }
    }

    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }
      setLocation();

      mymap = leaflet
        .map("mapid")
        .setView([store.getters.getRegister.latitude, store.getters.getRegister.longitude], 15);
      leaflet
        .tileLayer(
          "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYmFjb3MyNSIsImEiOiJja3Y2a2pyNmk5YWZxMzBxanVrNno3NnE0In0.okOhpHHfbDXptqipC8iWTg",
          {
            attribution: "bacos25",
            maxZoom: 18,
            id: "mapbox/streets-v11",
            accessToken:
              "pk.eyJ1IjoiYmFjb3MyNSIsImEiOiJja3Y2a2pyNmk5YWZxMzBxanVrNno3NnE0In0.okOhpHHfbDXptqipC8iWTg",
          }
        )
        .addTo(mymap);
      marker.value = leaflet.marker(
        [store.getters.getRegister.latitude, store.getters.getRegister.longitude],
        {
          draggable: true,
        }
      );

      marker.value.on("dragend", function (event) {
        latitude.value = event.target._latlng.lat;
        longitude.value = event.target._latlng.lng;
      });

      marker.value.addTo(mymap);
    });

    const mylo = () => {

      navigator.geolocation.getCurrentPosition((position) => {
        let newLatLng = new leaflet.LatLng(
          position.coords.latitude,
          position.coords.longitude
        );
        marker.value.setLatLng(newLatLng);
        console.log(newLatLng);
        mymap.panTo(newLatLng);
      });
    };

    const onSubmitRegister = (values) => {
      values.birthday = setday(values.day, values.month, values.year);
      values.age = check_birthdate(values.birthday);
      values.id_line = store.getters.getAccessToken.accesstoken;
      values.health = 1; // update ว่าสมัครสุขภาพแล้ว
      store.dispatch(SET_REGISTER, values);
      console.log(store.getters.getRegister);
      Sconfirm("ยืนยัน การลงทะเบียน", "question").then(async (result) => {
        if (result.isConfirmed) {
          isLoading.value = true;

          await UpdateUser(store.getters.getRegister)
            .then((result) => {
              isLoading.value = false;
              if (result.data.status === "success") {
                SconfirmNc("ลงทะเบียนสำเร็จ", "success", "ตกลง").then(() => {
                  if (store.getters.getUrlCallBack.id) {
                    liff.ready.then(() => {
                      liff.openWindow({
                        url: store.getters.getUrlCallBack.id,
                        external: false,
                      });
                    });
                  } else {
                    router.push({ name: "Home" });
                  }
                });
              } else {
                SconfirmNc("ไม่มีสิทธิ์เข้าใช้งาน", "error", "ตกลง").then(
                  () => {
                    liff.ready.then(() => {
                      liff.closeWindow();
                    });
                  }
                );
              }
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data.errors;

                if (errors.id_line) {
                  SToast("error", `${errors.id_line[0]}`);
                } else {
                  SToast("error", "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง");
                }
              }
            });
        }
      });



    };

    const setRange = () => {
      let year = moment().year() + 543;
      let value = [];
      let i;
      for (i = year; i >= year - 100; i--) {
        value.push(i);
      }
      return value;
    };

    const check_birthdate = (birthday) => {
      var birthdate = moment(birthday, "YYYYMMDD");
      var date_compare = moment("YYYYMMDD");
      var chk_age = moment().diff(moment(birthdate, date_compare), "years");
      //console.log(chk_age);
      return chk_age;
    };

    const setday = (day, month, year) => {
      let y = year - 543;
      let m = month;
      let d = day;
      return y + "-" + m + "-" + d;
    };


    const back = () => {
      router.push({ name: "Register_2" });
    };

    return {
      onSubmitRegister,
      register,
      back,
      mylo,
      latitude,
      longitude,
      data_register,
      color,
      monthNamesThai,
      setRange

    };
  },
});
</script>

<style scoped>
.card-body {
  padding: 0;
}

.myl {
  line-height: 40px;
  text-align: center;
  border: 1px solid #ccc;
  font-size: 17px;
}

@import "https://unpkg.com/leaflet@1.7.1/dist/leaflet.css";
</style>
