import ApiService from "@/service/ApiService";

export default function useHealth() {
  
  const Sos_alert = async (data) => {
    return await ApiService.post("sos_alert", data);
  };


  const Health_history = async (id) => {
    return await ApiService.get("edit_user/" + id);
  };



  return {
    Sos_alert,
    Health_history
  };
}
