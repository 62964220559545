export const SET_SOS = "setSos";
export const SET_HEALTH = "setHealth";

export default {
  state: {
    sos: [],
    health: [],
  },
  getters: {
    getSos(state) {
      return state.sos ;
     
    },
    getHealth(state) {
      return state.health ;
     
    },
  },
  actions: {
    [SET_SOS](state, payload) {
      state.commit(SET_SOS, payload);
    },
    [SET_HEALTH](state, payload) {
      state.commit(SET_HEALTH, payload);
    }
  },
  mutations: {
    [SET_SOS](state, payload) {
      state.sos = {
        ...state.sos,
        ...payload,
      };
    },
    [SET_HEALTH](state, payload) {
      state.health = {
        ...state.health,
        ...payload,
      };
    },
  },
};
