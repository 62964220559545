<template>
  <div class="card-header text-end">

    <img src="@/assets/bg.png" class="image-bg" alt="image" />

    <div style="
                    width: 135px;
                    height: 60px;
                    position: absolute;
                    z-index: 1;
                    left: 15%;
                    top: 23%;
                    transform: translate(-50%, -50%);
                    text-align: center;
                    background-color:transparent;
                  ">

      <img src="@/assets/logo.png"
        style="width: 65%;  border-radius: 50%; border-style: solid;   border-color: white;  border-width: 2px;" />

    </div>

    <div class="row w-100 m-0 text-white title-box">

      <h6 class="title-text text-start mb-0">งานสาธารณสุข</h6>
      <h5 class="subtitle-text text-start ms-1">อบจ.นครศรีธรรมราช</h5>
    </div>


    <div class="row mn-profile m-0 w-100 text-center">
      <div class="col-8">
        <div class="row align-item-center" @click="dashboard_history">
          <div class="col-3">
            <div style="
                        width: 50px;
                        position: relative;
                        z-index: 1;
                        ">
              <img src="@/assets/heart.png" width="120%" />
            </div>
          </div>
          <div class="col-9">
            <div class="box-title text-white d-flex align-items-center justify-content-center">
              <h3 >ข้อมูลสุขภาพ</h3>
            </div>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="box-main text-white position-absolute pb-1 end-0 me-2" style="bottom:10%" @click="register_confirm">

          <img src="@/assets/register_health.png" style="width: auto;" />
          <h6 class="mt-1" style="color: #570C7A">ลงทะเบียนสุขภาพ</h6>

        </div>
      </div>

    </div>

  </div>
</template>

<script>
import liff from "@line/liff";
import useSweetalert from "@/service/sweetalert2";
import { defineComponent, inject } from "vue";
export default defineComponent({
  name: "Header",
  setup() {
    const router = inject("router");
    const AppName = process.env.VUE_APP_NAME;
    const { SConfirmh } = useSweetalert();
    const setProfile = () => {
      liff.ready.then(() => {
        liff.openWindow({
          url: `line://app/${process.env.VUE_APP_LIFF_REGISTER}`,
          external: false,
        });
      });
    };

    const register_confirm = () => {
      SConfirmh("ลงทะเบียนข้อมูลสุขภาพ", "ลงให้ตนเอง", "ลงให้ผู้อื่น", "ยกเลิก!").then((result) => {
        if (result.isConfirmed) {
          console.log('self');

          router.push({ name: "Register_health_self" });

        } else if (result.isDenied) {
          console.log('error');
          router.push({ name: "Register_health_other1" });
        }


      });
    };

    const dashboard_history = () => {
      router.push({ name: "Dashboard_history" });
    }



    return {
      setProfile,
      AppName,
      register_confirm,
      dashboard_history
    };
  },
});
</script>

<style scoped>
.card-header {
  position: relative;
  height: auto;
  min-height: 200px;
  background-color: transparent !important;
  overflow: hidden;
  border-bottom: none !important;
  padding: 0 !important;
}


.title-box {
  position: absolute;
  top: 12%;
  left: 26%;
}

.title-text {
  font-size: 30px;

}

.subtitle-text {
  font-size: 16px;
}
</style>
