import ApiService from "@/service/ApiService";

export default function useRegister() {
  
  const Register = async (data) => {
    return await ApiService.post("register", data);
  };

  const UpdateUser = async (data) => {
    return await ApiService.put("edit_user/" + data.id_line, data);
  };

  const Register_health = async (data) => {
    return await ApiService.post("register_health", data);
  };

  return {
    Register,
    UpdateUser,
    Register_health
  };
}
