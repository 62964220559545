<template>
  <div class="row">
    <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true" :color="color" loader="dots" />
    <div class="col-12 f13">
      <div class="card concard card-shadow">
        <div class="card-header white text-center">
          <img src="@/assets/logo.png"
            style="width: 100px; border-radius: 50%;   box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;" />
          <div class="t1" style="margin: 5px">ลงทะเบียนข้อมูลสุขภาพ</div>
          <div class="t1" style="margin: 5px">(ผู้อื่น)</div>
        </div>
        <Form @submit="onSubmitRegister" :validation-schema="register" id="kt_account_profile_details_form" class="form"
          novalidate="novalidate">
          <div class="card-body mb-47 mt-2 white">
            <!--begin::Card body-->
            <div class="card-body">




              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label fw-bold fs-6">โรคประจำตัว (ถ้ามี)</label>
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field type="text" name="self_disease" class="form-control  form-control-solid"
                    placeholder="โรคประจำตัว" v-model="data_register.self_disease" />
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->

              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label fw-bold fs-6">แพทย์ประจำตัว (ถ้ามี)</label>
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field type="text" name="self_doctor" class="form-control  form-control-solid" placeholder="แพทย์ประจำตัว"
                    v-model="data_register.self_doctor" />
                </div>
                <!--end::Col-->
              </div>
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label fw-bold fs-6">อัตราการเต้นของหัวใจ (ถ้ามี)</label>
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field type="text" name="heart_rate" class="form-control  form-control-solid"
                    placeholder="อัตราการเต้นของหัวใจ" v-model="data_register.heart_rate" />
                </div>
                <!--end::Col-->
              </div>



              <div class="row">
                <label class="col-lg-4 col-form-label fw-bold fs-6">ค่าความดัน (ถ้ามี)</label>
                <div class="col-6">
                  <!--begin::Input group-->
                  <div class="row mb-6">
                    <!--begin::Label-->
                    <label class="col-lg-4 col-form-label fw-bold fs-6">บน</label>
                    <!--end::Label-->

                    <!--begin::Col-->
                    <div class="col-lg-8 fv-row">
                      <Field type="text" name="upper_pressure" class="form-control  form-control-solid" placeholder="บน"
                        v-model="data_register.upper_pressure" />
                    </div>
                    <!--end::Col-->
                  </div>
                  <!--end::Input group-->
                </div>
                <div class="col-6">
                  <!--begin::Input group-->
                  <div class="row mb-6">
                    <!--begin::Label-->
                    <label class="col-lg-4 col-form-label fw-bold fs-6">ล่าง</label>
                    <!--end::Label-->

                    <!--begin::Col-->
                    <div class="col-lg-8 fv-row">
                      <Field type="text" name="moo" class="form-control  form-control-solid" placeholder="ล่าง"
                        v-model="data_register.lower_pressure" />
                    </div>
                    <!--end::Col-->
                  </div>
                  <!--end::Input group-->
                </div>
              </div>


              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label fw-bold fs-6">อุณหภูมิร่างกาย (ถ้ามี)</label>
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field type="text" name="helth_temp" class="form-control  form-control-solid"
                    placeholder="อุณหภูมิร่างกาย" v-model="data_register.helth_temp" />
                </div>
                <!--end::Col-->
              </div>

              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label fw-bold fs-6">ออกซิเจนในเลือด (ถ้ามี)</label>
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field type="text" name="blood_oxygen" class="form-control  form-control-solid"
                    placeholder="ออกซิเจนในเลือด" v-model="data_register.oxygen" />
                </div>
                <!--end::Col-->
              </div>

              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6">กรุ๊ปเลือด</label>
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field as="select" name="blood_type" class="form-control  form-control-solid" @change="checkFn($event)"
                    v-model="data_register.blood_type">
                    <option value="" selected disabled>โปรดเลือก</option>
                    <option value="A">A</option>
                    <option value="B">B</option>
                    <option value="AB">AB</option>
                    <option value="O">O</option>
                    <option value="อื่นๆ">อื่นๆ</option>
                  </Field>
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="blood_type" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
                <!--begin::Col-->
                <div class="col-lg-8 fv-row mt-1" v-if="fn_status">
                  <Field type="text" name="blood_type_other" class="form-control  form-control-solid"
                    placeholder="กรุ๊ปเลือด" v-model="data_register.fn_other" />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="blood_type_other" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>


              <div class="row">
                <div class="col-6">
                  <!--begin::Input group-->
                  <div class="row mb-6">
                    <!--begin::Label-->
                    <label class="col-lg-4 col-form-label fw-bold required fs-6">น้ำหนัก (kg.)</label>
                    <!--end::Label-->

                    <!--begin::Col-->
                    <div class="col-lg-8 fv-row">
                      <Field type="text" name="weight" class="form-control  form-control-solid" placeholder="บน"
                        v-model="data_register.weight" />
                      <div class="fv-plugins-message-container">
                        <div class="fv-help-block">
                          <ErrorMessage name="weight" />
                        </div>
                      </div>
                    </div>
                    <!--end::Col-->
                  </div>
                  <!--end::Input group-->
                </div>
                <div class="col-6">
                  <!--begin::Input group-->
                  <div class="row mb-6">
                    <!--begin::Label-->
                    <label class="col-lg-4 col-form-label fw-bold required fs-6">ส่วนสูง (cm.)</label>
                    <!--end::Label-->

                    <!--begin::Col-->
                    <div class="col-lg-8 fv-row">
                      <Field type="text" name="height" class="form-control  form-control-solid" placeholder="ส่วนสูง"
                        v-model="data_register.height" />
                    </div>
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="height" />
                      </div>
                    </div>
                    <!--end::Col-->
                  </div>
                  <!--end::Input group-->
                </div>
              </div>

              <div class="row mt-2 text-center" v-if="data_register.height != 0 && data_register.weight != 0">

                <h4>ค่า BMI : {{ bmi[0] }}</h4><br>
                <h4>อยู่ในเกณท์ : {{ bmi[1] }}</h4><br>
                <h4>ภาวะเสี่ยงต่อโรค : {{ bmi[2] }}</h4>
              </div>

            </div>

            <!--end::Card body-->
          </div>
          <div class="card-footer white">
            <div class="d-flex justify-center align-center">
              <div style="width: 100%; padding: 0px 5px">
                <button type="button" @click="back" class="button btn-back">
                  ย้อนกลับ
                </button>
              </div>
              <div style="width: 100%; padding: 0px 5px">
                <button type="submit" style="width: 100%" class="button">
                  ลงทะเบียน
                </button>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { defineComponent, ref, onMounted, inject, computed } from "vue";
import { SET_REGISTER } from "@/store/modules/RegisterModel";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
//import { SET_HEALTH } from "@/store/modules/HealthModel";
import useRegister from "@/service/api/register";
import useSweetalert from "@/service/sweetalert2";
import liff from "@line/liff";
export default defineComponent({
  name: "Register",
  components: {
    ErrorMessage,
    Field,
    Form,
    Loading,
  },
  setup() {
    const store = inject("store");
    const router = inject("router");
    const { Sconfirm, SconfirmNc, SToast } = useSweetalert();
    const { Register_health } = useRegister();
    
    let isLoading = ref(false);
    let color = ref(process.env.VUE_APP_COLOR);
    const data_register = store.getters.getRegister;
    let fn_status = ref(false);

    //const bmi = ref('');
    const register = Yup.object().shape({

      blood_type: Yup.string().required("กรุณาเลือก กรุ๊ปเลือด"),
      blood_type_other: Yup.string()
        .when("blood_type", {
          is: (blood_type) => blood_type === "อื่นๆ",
          then: Yup.string().required("กรุณากรอก กรุ๊ปเลือด"),
        }),
      self_disease: Yup.string(),
      self_doctor: Yup.string(),
      heart_rate: Yup.string(),
      upper_pressure: Yup.string(),
      lower_pressure: Yup.string(),
      blood_oxygen: Yup.string(),
      helth_temp: Yup.string(),
      weight: Yup.string(),
      height: Yup.string(),
    });

    const bmi = computed(() => {
      let bmi_score = (data_register.weight / (data_register.height / 100) ** 2).toFixed(2);
      let h_data = [];
      if (bmi_score < 18.5) {
        h_data = [bmi_score, 'น้ำหนักน้อย / ผอม', 'มากกว่าคนปกติ'];
      } else if (bmi_score > 18.5 && bmi_score <= 22.90) {
        h_data = [bmi_score, 'ปกติ (สุขภาพดี)', 'เท่าคนปกติ'];
      } else if (bmi_score > 23 && bmi_score <= 24.90) {
        h_data = [bmi_score, 'ท้วม / โรคอ้วนระดับ 1', 'อันตรายระดับ 1'];
      } else if (bmi_score > 25 && bmi_score <= 29.90) {
        h_data = [bmi_score, 'อ้วน / โรคอ้วนระดับ 2', 'อันตรายระดับ 2'];
      } else {
        h_data = [bmi_score, 'อ้วน / โรคอ้วนระดับ 3', 'อันตรายระดับ 3'];
      }
      return h_data;
    });

    const back = () => {
      router.push({ name: "Register_health_other2" });
    };

    function checkFn(event) {
      if (event.target.value === "อื่นๆ") {
        fn_status.value = true;
      } else {
        fn_status.value = false;
      }
    }


    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Dashboard" });
      }
    });

    const onSubmitRegister = (values) => {
      values.type = "other";
      values.id_line = store.getters.getAccessToken.accesstoken;
      values.bmi = bmi.value[0];
      values.bmi_rule = bmi.value[1];
      values.bmi_disease = bmi.value[2];
      store.dispatch(SET_REGISTER, values);
     

      console.log(store.getters.getRegister);

      Sconfirm("ยืนยัน การลงทะเบียน", "question").then(async (result) => {
        if (result.isConfirmed) {
          isLoading.value = true;

          await Register_health(store.getters.getRegister)
            .then((result) => {
              isLoading.value = false;
              if (result.data.status === "success") {
                //store.dispatch(SET_HEALTH, result.data.data);
                SconfirmNc("ลงทะเบียนสำเร็จ", "success", "ตกลง").then(() => {
                  if (store.getters.getUrlCallBack.id) {
                    liff.ready.then(() => {
                      liff.openWindow({
                        url: store.getters.getUrlCallBack.id,
                        external: false,
                      });
                    });
                  } else {
                    router.push({ name: "Home" });
                  }
                });
              } else {
                SconfirmNc("ไม่มีสิทธิ์เข้าใช้งาน", "error", "ตกลง").then(
                  () => {
                    liff.ready.then(() => {
                      liff.closeWindow();
                    });
                  }
                );
              }
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data.errors;

                if (errors.id_line) {
                  SToast("error", `${errors.id_line[0]}`);
                } else {
                  SToast("error", "เกิดข้อผิดผาด กรุณาลองใหม่อีกครั้ง");
                }
              }
            });
        }
      });
    };

    return {
      back,
      onSubmitRegister,
      register,
      data_register,
      isLoading,
      color,
      bmi,
      checkFn,
      fn_status
    };
  },
});
</script>

<style scoped>
.icon {

  width: 50px;
  padding: 5px;
}

.gender {
  padding: 30px 20px;
  border: 1px solid #dee2e6;
}

input[type="radio"] {
  display: none;
}

input:checked+span {
  color: var(--color);
  border: 2px solid var(--color);
}
</style>
