import { createStore } from "vuex";

import ProfireLine from "@/store/modules/ProfireLine";
import AuthModule from "@/store/modules/AuthModule";
import RegisterModel from "@/store/modules/RegisterModel";
import NewsModel from "@/store/modules/NewsModel";
import HealthModel from "@/store/modules/HealthModel";

const store = createStore({
  modules: {
    ProfireLine,
    AuthModule,
    RegisterModel,
    NewsModel,
    HealthModel
  },
});

export default store;
