<template>
  <div class="bg-home">
    <div class="card concard card-shadow">
      <Headerhistory></Headerhistory>

      <div class="card-body mt-1" style="padding:16px">
        <h1 class="text-center pb-2">ข้อมูลสุขภาพ</h1>

        <div class="col-12 p-1" style="overflow-y: scroll; height: 40rem;">
          <div v-for="(data, index) in Health_history" :key="index" style="background-color: transparent" @click="go_detail(index)">
            <ul class="line pt-2 pb-3">

              <h2 style="font-weight:600;">คุณ {{ data.firstname }} {{ data.lastname }}</h2>
              {{ data.id_card }} กรุ๊ปเลือด {{ data.blood_type }}

            </ul>

          </div>
        </div>




      </div>

    </div>

  </div>
</template>

<script>
//import moment from "moment";
import useSweetalert from "@/service/sweetalert2";
import { defineComponent, onMounted, inject  } from "vue";
import Headerhistory from "@/components/Headerhistory";

export default defineComponent({
  name: "Dashboard_history",
  components: {
    Headerhistory,
  },
  setup() {

    const { Shealthinfo } = useSweetalert();
    const store = inject("store");
    const router = inject("router");
    const Health_history = store.getters.getHealth;

    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }
    });

    const go_detail = (index) => {
      // console.log(Health_history[index].birthday);
      // Health_history[index].birthday = dateThai(Health_history[index].birthday);
      Shealthinfo('ข้อมูลสุขภาพ', Health_history[index]);
    }

    // function dateThai(date) {
    //   let y = moment(date).format("YYY");
    //   let year = parseInt(y) + 543;
    //   let march = moment(date).locale("th");

    //   return march.format("DD/MM/" + year);
    // }


    return {
      Health_history,
      go_detail
    };

  },
});
</script>

<style scoped>
.line {
  border-bottom: 3px solid rgb(201, 201, 201);
  width: 100%;
}

.bg-home {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("~@/assets/bg-dash.png");
}

.info-box {
  color: #373737 !important;
  box-sizing: border-box;
  display: flex;
  border-radius: 22px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  height: 100px;
  width: 95% !important;
  cursor: pointer;
  padding: 0px;
  align-items: center;
  /* Some padding */
}

.info-box img {
  /* Set the width to 100% to fit the image inside the button */
  width: 80px;
  border-radius: 22px 0px 0px 22px;
  height: 100px;
  vertical-align: middle;
  margin-right: 20px;
  /* Add some space between the image and the text */
}


.info-box:hover,
.info-box:focus {
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  color: rgba(0, 0, 0, 0.65);
}

.info-box:hover {
  transform: translateY(-1px);
}

.info-box:active {
  background-color: #d4d4d4;
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  color: rgba(0, 0, 0, 0.65);
  transform: translateY(0);
}

.col-7 {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.info-box-body {
  width: fit-content;
  display: flex;
  position: relative;
}

.info-box-title {
  margin-top: 5px;
  width: 50%;
  font-size: 20px;
  font-weight: 800;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.info-box-detail {
  width: 10rem;
  font-size: 14px;
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.news-scroll {
  height: 40rem !important;
  /* set a fixed height for the card body */
  overflow: auto;
  /* enable a scrollbar when the content overflows */
}

.swal2-close-button {
  position: absolute;
  top: 0;
  right: 0;
}

.swal2-button-horizontal {
  padding: 10px 20px;
  border: 1px solid #ccc;
}
</style>
